<template>
  <div class="pagination">
    <div class="pagination-button" @click="toPage(1)">首页</div>
    <div
      v-if="current > 1"
      class="pagination-button pagination-button-prev"
      @click="toPage(current - 1)"
    >
      上一页
    </div>
    <template v-for="n in total">
      <div
        class="pagination-item"
        :class="{ 'pagination-item_active': current == n }"
        v-if="n <= current + range && n >= current - range"
        :key="n"
        @click="toPage(n)"
      >
        {{ n }}
      </div>
    </template>
    <div
      v-if="current < total"
      class="pagination-button pagination-button-next"
      @click="toPage(current + 1)"
    >
      下一页
    </div>
    <div class="pagination-button" @click="toPage(total)">末页</div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    total: {
      require: true,
      type: Number,
    },
    current: {
      type: Number,
      default: 1,
    },
    range: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 跳页
     */
    toPage(page) {
      this.$emit("change", page);
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    &-button {
      margin: 0 10px;
      padding: 0 30px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #818181;
      border: 1px solid #bfbfbf;
      cursor: pointer;
      font-size: 24px;
    }

    &-item {
      cursor: pointer;
      margin: 0 10px;
      width: 58px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #818181;
      border: 1px solid #bfbfbf;
      font-size: 24px;

      &_active {
        background-color: #ee2e83;
        color: #fff;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rpx(20);
    margin: rpx(40) 0;

    &-button {
      padding: 0 rpx(30);
      height: rpx(54);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #818181;
      border: rpx(2) solid #bfbfbf;
      cursor: pointer;
      font-size: rpx(24);
      margin: 0 rpx(10);

      &-prev,
      &-next {
        display: none;
      }
    }

    &-item {
      margin: 0 rpx(10);
      cursor: pointer;
      width: rpx(58);
      height: rpx(54);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #818181;
      border: 1px solid #bfbfbf;
      font-size: rpx(24);

      &_active {
        background-color: #ee2e83;
        color: #fff;
      }
    }
  }
}
</style>
