<template>
  <container ref="container" @error="init">
    <container ref="news" @error="getData">
      <!-- 新闻 -->
      <div class="news">
        <!-- 新闻-列表 -->
        <div class="news-list">
          <!-- 新闻-元素 -->
          <news-card :detail="item" v-for="item in news.list" :key="item.id" />
        </div>

        <!-- 分页 -->
        <pagination
          :total="news.total"
          :current="news.page"
          @change="getData"
        />
      </div>
    </container>

    <!-- 视频 -->
    <publicSection
      class="video"
      :header="require('@/assets/image/public/videoIntroduction.png')"
    >
      <!-- 视频-列表 -->
      <div class="video-list">
        <!-- 视频-元素 -->
        <video-card
          class="video-card"
          v-for="item in video.list"
          :key="item.id"
          :detail="{
            src: item.value,
            cover: item.img,
          }"
        />
      </div>
    </publicSection>
  </container>
</template>

<script>
import publicSection from "@/components/publicSection.vue";
import pagination from "@/components/pagination.vue";
import newsCard from "@/components/news-card.vue";
import videoCard from "@/components/video-card.vue";

export default {
  name: "news",
  components: {
    publicSection,
    pagination,
    newsCard,
    videoCard,
  },
  data() {
    return {
      news: {
        list: [],
        page: 1,
        total: 1,
      },
      video: {
        list: [],
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        //
        this.getData(1);

        // 获取音频
        const video = await this.$axios("/api/mainstation/aboutUsvideo");

        // 设置数据
        this.video.list = video.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },

    /**
     * 获取列表
     */
    async getData(page) {
      try {
        // 设置页号
        this.news.page = page || this.news.page;

        // 设置状态
        this.$refs.news.status("init", {
          height: "50vh",
        });

        const [news] = await Promise.all([
          this.$axios("/api/mainstation/joinDynamic", {
            params: {
              type: 2,
              num: 5,
              page: this.news.page,
            },
          }),
        ]);

        // 设置数据
        this.news.list = news.data.newslist;
        this.news.total = news.data.pagenum;

        // 设置状态
        this.$refs.news.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.news.status("error", {
          text: e.message,
          height: "50vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 企业
  .news {
    width: 1200px;
    margin: auto;

    // 企业-列表
    &-list {
      padding: 40px 0;
    }
  }

  // 视频
  .video {
    padding-top: 20px;
    padding-bottom: 70px;
    width: 1200px;
    margin: auto;

    // 视频-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
    }

    // 视频-元素
    &-card {
      margin-right: 20px;

      &:nth-of-type(2) {
        margin-right: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 企业
  .news {
    width: 100%;
    margin: auto;

    // 企业-列表
    &-list {
      padding: rpx(40) 0;
    }
  }

  // 视频
  .video {
    padding-top: rpx(30);
    padding-bottom: rpx(50);
    width: 100%;
    margin: auto;

    // 视频-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: rpx(40);
    }

    // 视频-元素
    &-card {
      margin-bottom: rpx(30);
    }
  }
}
</style>
