<template>
  <div class="video-card">
    <video
      @play="play = true"
      @pause="play = false"
      @onended="play = false"
      ref="video"
      :controls="play"
      class="video-card-player"
      :src="detail.src"
      :poster="detail.cover"
    ></video>
    <iconfont
      @click="$refs.video.play()"
      v-if="!play"
      class="video-card-icon"
      name="play"
      size="54px"
    />
  </div>
</template>

<script>
export default {
  name: "video-card",
  computed: {},
  props: {
    detail: Object,
  },
  data() {
    return {
      play: false,
    };
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  // 视频
  .video-card {
    width: 590px;
    height: 320px;
    // cursor: pointer;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &-cover {
      @include cover;
    }

    &-player {
      @include cover;
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      z-index: 101;
      // height: 54;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  // 视频
  .video-card {
    width: rpx(670);
    height: rpx(360);
    // cursor: pointer;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &-cover {
      @include cover;
    }

    &-player {
      @include cover;
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      z-index: 101;
      // height: 54;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
    }
  }
}
</style>
