<template>
  <div class="section">
    <div class="section-header">
      <img :src="header" />
    </div>
    <div class="section-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicSection",
  props: {
    header: String,
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  // 段落
  .section {
    // 段落-顶部
    &-header {
      width: 1200px;
      display: block;
      margin: auto;
      user-select: none;

      img {
        height: 110px;
        width: 440px;
        display: block;
        margin: auto;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  // 段落
  .section {
    // 段落-顶部
    &-header {
      width: rpx(750);
      display: block;
      margin: auto;
      user-select: none;

      img {
        @include cover;
        width: rpx(440);
        height: rpx(110);
        margin: auto;
      }
    }
  }
}
</style>
